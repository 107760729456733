<template>
	<div class="" style="background-color: #f4f3f4">
		<div class="Main width_1200 margin_auto flex">
			<div class="Main_l">
				<img
					v-lazy="SpecialDetails.pic"
					:key="SpecialDetails.pic"
					alt=""
					class="jszkimg"
				/>
				<div class="Main_l_bot">
					<div>MetaEra 周刊｜6.5-6.11</div>
					<div>
						MetaEra周刊第114期，带您回顾一周热点、重要新闻、行情与合约数据、矿业信息、项目动态、技术进展等全行业资讯动态
					</div>
				</div>
			</div>
			<div class="MainList">
				<div
					class="MainItem tophit"
					:ref="'id' + item.id"
					v-for="(item, index) in SpecialDetails.subordinate"
					:key="index"
				>
					<div class="MainItemHead flex align_items_c">
						<div>{{ item.name }}</div>
					</div>
					<div
						class="wzitem wzitem2 flex align_items_c pointer"
						v-for="(items, indexs) in item.data"
						:key="index"
						@click="
							$router.push({
								path: '/kuaixun_wz',
								query: { id: items.id },
							})
						"
					>
						<div class="flex align_items_c justify_content_c">快讯</div>
						<div class="hovercolor">{{ items.title }}</div>
					</div>
					<!-- <div class="wzitem wzitem2 flex align_items_c">
                        <a href="">展开更多</a>
                        <i class="el-icon-arrow-down"></i>
                    </div> -->
				</div>
			</div>
			<div class="steps">
				<div class="js-box">
					<div class="js-box-right-box">
						<div class="nav-box">
							<ul class="nav-list">
								<li
									v-for="(item, index) in WeekDetails.subordinate"
									:key="index"
									:class="{ active: active == index }"
									class="pointer"
									@click="md(item, index)"
									>{{ item.name }}</li
								>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getWeekDetails } from "@/api/home";
export default {
	name: "",
	data() {
		return {
			active: 0,

			WeekDetails: {},
		};
	},
	created() {
		this.getWeekDetails();
	},
	methods: {
		getWeekDetails() {
			getWeekDetails({ id: this.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						this.WeekDetails = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},

		md(item, index) {
			this.active = index;
			this.$el
				.querySelector(".id" + item.id)
				.scrollIntoView({ block: "start", behavior: "smooth" });
		},
	},
};
</script>

<style scoped>
/* Main */
.Main {
	padding-top: 36px;
}

.MainList {
	margin-left: 25px;
	padding-bottom: 20px;
}

.MainItem {
	width: 686px;
	background: #ffffff;
	border-radius: 18px 18px 18px 18px;
	opacity: 1;
	margin-top: 20px;
}

.MainItem:nth-child(1) {
	margin-top: 0;
}

.MainItemHead {
	width: 100%;
	height: 61px;
	border-bottom: 1px solid #efefef;
	border-radius: 20px 20px 0 0;
	position: relative;
}

.MainItemHead div {
	position: relative;
	margin-left: 20px;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	padding-left: 20px;
}

.MainItemHead div::after {
	position: absolute;
	content: "";
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 3px;
	height: 18px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 2px 2px 2px 2px;
	opacity: 0.85;
}

.wzitem {
	width: 94%;
	margin: 0 auto;
	height: 142px;
	border-bottom: 1px solid #efefef;
}

.wzitem img {
	width: 160px;
	height: 100px;
	border-radius: 5px;
}

.wzitemL {
	width: 70%;
	margin-left: 21px;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.wzitemL div:nth-child(1) {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.wzitemL div:nth-child(2) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a7;
}

.wzitem2 {
	height: 62px;
}

.wzitem2 div:nth-child(1) {
	width: 42px;
	height: 21px;
	background: #ffffff;
	border-radius: 2px 2px 2px 2px;
	opacity: 1;
	border: 1px solid #0056ff;
	font-size: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.wzitem2 div:nth-child(2) {
	font-size: 17px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-left: 10px;
}

.wzitem2 a {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.wzitem2 i {
	color: #0056ff;
}

.steps {
	width: 100px;
	margin-left: 10px;
}

.stepsTime {
	font-size: 12px;
	font-family: DINPro-Regular, DINPro;
	font-weight: 400;
	color: #000000;
}

.stepsVal {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000 !important;
	margin-top: 5px;
}

.js-box {
	margin-top: 0;
	position: fixed;
}
</style>
<style></style>
